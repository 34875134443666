const options: Intl.NumberFormatOptions = {
  currency: "EUR",
  style: "currency",
};

/**
 * Belgium Dutch shows the euro in front of the number but still has commas as a
 * separating character. As our designers prefer it with a euro in front we
 * will use it for the currency conversion.
 */
const belgiumLocale = "nl-BE";

const numberFormatWithFractions = new Intl.NumberFormat(belgiumLocale, options);
const numberFormatWithoutFractions = new Intl.NumberFormat(belgiumLocale, {
  ...options,
  maximumFractionDigits: 0,
  minimumFractionDigits: 0,
});

export function transformToCurrency(value: number): string {
  const showFractions = value % 1 !== 0;

  return showFractions ? numberFormatWithFractions.format(value) : numberFormatWithoutFractions.format(value);
}
